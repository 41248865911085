/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")
const { FirebaseProvider } = require("./src/context/FirebaseProvider")
const { AppProvider } = require("./src/context")
const Hotjar = require('@hotjar/browser').default;
const Sentry = require("@sentry/react")
const { browserTracingIntegration } = require("@sentry/browser")

const siteId = 2804378
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

Sentry.init({
  dsn:
    "https://07e0ce9afcf7449e87819631a978e785@o4504615611596800.ingest.sentry.io/4504615618805760",
  environment: process.env.GATSBY_ACTIVE_ENV,
  integrations: [
    browserTracingIntegration(),
    Sentry.replayIntegration({
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 0.2,
      replaysOnErrorSampleRate: 1.0,
    }),
  ],
})

exports.wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <FirebaseProvider>{element}</FirebaseProvider>
    </AppProvider>
  )
}

/* exports.onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
} */
